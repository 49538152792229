import { Controller } from '../Controller';
import { IGroupV2 } from '../../contexts/Group/IGorupV2';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { fallbackMembership } from '@wix/social-groups-api';
import { PubSubObserver } from '../pubSub/PubSubObserver';
import { PubSubEventTypes } from '../pubSub/PubSubEventTypes';
import { UserRequestResponse } from '../../../../common/context/user/IUserContext';
import type { HttpError } from '@wix/fe-essentials/http-client';
import { LeaveRequest } from '@wix/ambassador-social-groups-v2-group-member/types';
import { OwnershipFilter } from '@wix/ambassador-social-groups-v2-group-request/types';

export class GroupV2Controller
  extends Controller<IGroupV2>
  implements PubSubObserver
{
  constructor(controllerContext: ControllerParams, groupId: string) {
    super(controllerContext, groupId);
    this.onUserLogin(async () => {
      const id = await this.getGroupId();
      this.getGroupV2(id!).then((props) => this.setProps(props));
    });
    this.setSubscriptions();
  }

  pageReady() {
    return Promise.resolve();
  }

  async getInitialProps(): Promise<Partial<IGroupV2>> {
    try {
      const id = await this.getGroupId();
      return this.getGroupV2(id!);
    } catch (e) {
      console.log('GroupV2Controller.getInitialProps Error', e);
      return Promise.resolve({});
    }
  }

  private async getGroupV2(id: string) {
    // TODO: serverless candidate
    const [group, memberships] = await Promise.all([
      this._api
        .getGroupApi()
        .getGroupById(id!)
        .catch((e: HttpError) => {
          return this.handleGetGroupError(id, e) || {};
        }),
      this._api
        .getGroupMembersApi()
        .queryMemberships([id!])
        .catch((e) => {
          console.log(
            '[GroupV2Controller.getGroupV2] queryMemberships Error',
            e,
          );
          return {} as any;
        }),
    ]);
    return {
      groupV2: group,
      membership: fallbackMembership(id!, memberships?.[id!]),
    };
  }

  setSubscriptions(): void {
    this.subscribe<UserRequestResponse>(
      PubSubEventTypes.JOIN_GROUP,
      ({ data }) => {
        this.getGroupV2(data.group.id!).then((props) => this.setProps(props));
      },
    );
    this.subscribe<LeaveRequest>(PubSubEventTypes.LEAVE_GROUP, ({ data }) => {
      this.getGroupV2(data.groupId!).then((props) => this.setProps(props));
    });
  }

  private async handleGetGroupError(id: string, err: HttpError) {
    if (err?.response?.status === 404) {
      const ownershipFilter = this.isUserAdmin()
        ? OwnershipFilter.ALL
        : OwnershipFilter.CURRENT_MEMBER;
      const groupRequest =
        (await this._api.getGroupApi().queryGroupRequests({
          ownershipFilter,
          query: {
            filter: {
              groupId: {
                $eq: id,
              },
            },
            paging: {
              offset: 0,
              limit: 1,
            },
          },
        })) || {};
      const [_groupRequest] = groupRequest?.groupRequests || [{}];
      return _groupRequest.group;
    }
    console.log('Error in [GroupV2Contoller.handleGetGroupError]', { id, err });
  }
}
