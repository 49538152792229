import {
  getRestrictedEventIds,
  isProfilePublic,
  restrictedByEvents,
} from '@wix/social-groups-api';

import { BaseWidgetController } from '../BaseWidgetController';
import {
  IUserActions,
  IUserContext,
  UserException,
  UserPermissions,
  UserRequestResponse,
  UserStatus,
} from '../../context/user/IUserContext';
import {
  I$WWrapper,
  IUser,
  IWixAPI,
} from '@wix/native-components-infra/dist/src/types/types';
import { Group } from '@wix/ambassador-social-groups-v2-group/types';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import {
  ApiErrorCode,
  errorEventFromAmbassador,
  ErrorOrigin,
  IErrorEvent,
} from '../../../components/Group/controllers/errorHandler/IErrorEvent';
import { AmbassadorHTTPError } from '@wix/ambassador/runtime/http';
import {
  JoinRequest,
  LeaveRequest,
} from '@wix/ambassador-social-groups-v2-group-member/types';
import { joinCommunity } from '@wix/ambassador-members-v1-member/http';
import { Member } from '@wix/ambassador-members-v1-member/types';
import { listMembershipQuestions } from '@wix/ambassador-social-groups-v2-membership-question/http';
import { MembershipQuestionAnswer } from '@wix/ambassador-social-groups-v2-membership-question/types';
import {
  AuthorizedGroupCreators,
  Settings,
} from '@wix/ambassador-social-groups-v2-groups-app-settings/types';
import { PubSubEventTypes } from '../../../components/Group/controllers/pubSub/PubSubEventTypes';
import { ApiDelegate } from '../../api/services/ApiDelegate';
import {
  INVITE_PAID_PLANS,
  IPaidPlans,
  PAID_PLANS,
} from '../../api/errors/IPaidPlans';
import { COMPONENT_ID } from '../../utils/utils';
import { BaseControllerContext } from 'common/controllers';
import { MEMBERS_APP_DEF_ID } from '../../../config/constants';
import { FEDOPS_GROUP_PAGE__JOIN_TO_GROUP } from '../../../config/fedopsInteractions';

interface PendingAction {
  resolve(value?: any): void;

  reject: (reason: IUserContext['userRequestResponse']) => void;
}

export class UserController extends BaseWidgetController<IUserContext> {
  private myMember: Member | undefined;
  private pendingAction: PendingAction | null = null;
  private readonly api: ApiDelegate;
  private settings: Settings | null = null;

  constructor(
    controllerContext: ControllerParams,
    private readonly groupId?: string,
  ) {
    super(controllerContext);
    this.onUserLogin(async () => {
      await this.setMyMember();
      this.setProps({
        userStatus: this.getUserStatus(),
        userPermissions: await this.getUserPermissions(),
        myMember: this.myMember,
        isSiteAdmin: this.isUserAdmin(),
      });
      this.pendingAction?.resolve();
    });
    this.api = ApiDelegate.getInstance(
      new BaseControllerContext(controllerContext),
    );
  }

  async pageReady(
    $w: I$WWrapper | undefined,
    wixAPI: IWixAPI | undefined,
  ): Promise<void> {
    if (this.shouldTriggerJoin() && this.groupId) {
      try {
        const group = await this.api.getGroupApi().getGroupById(this.groupId);
        await this.join(group!);
      } catch (e) {
        console.log('FAILED to join group with join trigger', e);
      }
    }
    return Promise.resolve();
  }

  async getInitialProps(): Promise<Partial<IUserContext>> {
    return {
      userStatus: await this.getUserStatus(),
      userActions: this.userActions,
      userPermissions: await this.getUserPermissions(),
      myMember: await this.getMyMember(),
      isSiteAdmin: this.isUserAdmin(),
    };
  }

  private readonly userActions: IUserActions = {
    pickPricingPlan: async (plans: UserRequestResponse) => {
      try {
        return this.pickPricingPlan(plans);
      } catch (e) {
        console.log('Error pickPricingPlan', e);
        // TODO: send report
      }
    },
    cancelJoinGroupRequest: async (joinRequest: JoinRequest) => {
      try {
        await this.api.getGroupMembersApi().cancelJoinGroupRequest(joinRequest);
      } catch (e) {
        console.log('Error cancelJoinGroupRequest', e);
        // TODO: send report
      }
    },
    leaveGroup: async (payload) => {
      try {
        await this.api.getGroupMembersApi().leaveGroup(payload);
        this.publish<LeaveRequest>(PubSubEventTypes.LEAVE_GROUP, payload);
      } catch (e) {
        console.log('Error leaveGroup', e);
        // TODO: send report
      }
    },
    ignoreException: (): void => {
      this.pendingAction?.reject(null);
      this.resetPendingAction(null);
    },
    changeGroupMembership: async (
      group: Group,
      membershipQuestionAnswers?: MembershipQuestionAnswer[],
      fromOrigin?: string,
    ) => {
      this.setProps({
        changeMembershipOrigin: fromOrigin,
      });
      await this.join(group, membershipQuestionAnswers);
    },
    makeProfilePublic: async () => {
      try {
        await this.joinCommunity();
        this.pendingAction?.resolve();
      } catch (e) {
        console.error('Update site member profile: FAIL', e);
        this.pendingAction?.reject(null);
      } finally {
        this.pendingAction = null;
      }
    },
    promptLogin: async (): Promise<IUser> => super.promptLogin({ modal: true }),
    openUserProfile: (siteMemberId: string) => {
      this.openUserProfile(siteMemberId).catch((e) =>
        console.log('Error in [UserController.userActions.openUserProfile]', e),
      );
    },
  };

  private async join(
    group: Group,
    membershipQuestionAnswers?: MembershipQuestionAnswer[],
  ) {
    this.setProps({
      userStatus: UserStatus.UPDATING,
      userRequestResponse: null,
    });
    try {
      await this.requestLogin();
      await this.requestPublicProfile(group);

      this.flowAPI.fedops.interactionStarted(FEDOPS_GROUP_PAGE__JOIN_TO_GROUP);

      const membershipResponse = await this.joinGroup(
        group,
        membershipQuestionAnswers,
      );
      this.publish<UserRequestResponse>(PubSubEventTypes.JOIN_GROUP, {
        group,
        membershipResponse,
      });
      this.setProps({ userStatus: this.getUserStatus() });
    } catch (e) {
      this.resetPendingAction(null);
    }
  }

  private async joinCommunity() {
    const httpClient = this.getHTTPClient();
    const { data } =
      (await httpClient.request(joinCommunity({}))) || ({} as any);
    this.myMember = data?.member;
  }

  private async requestLogin() {
    if (this.isUserLoggedIn()) {
      return;
    }
    return new Promise<void>((resolve, reject) => {
      super.promptLogin({ modal: true }).catch((e: any) => {
        console.error('Error in [UserController.requestLogin]: ', e);
        this.flowAPI.errorMonitor.captureException(e);
        this.resetPendingAction(null);
      });
      this.pendingAction = { resolve, reject };
    });
  }

  private resetPendingAction = (
    userRequestResponse: IUserContext['userRequestResponse'] = null,
  ) => {
    this.pendingAction = null;
    this.setProps({
      userRequestResponse,
      userStatus: this.getUserStatus(),
    });
  };

  private async getMyMember() {
    if (!this.isUserLoggedIn()) {
      return;
    }
    if (this.myMember) {
      return Promise.resolve(this.myMember);
    }
    await this.setMyMember();
    return this.myMember;
  }

  private async setMyMember() {
    try {
      this.myMember = await this.api.getMembersApi().getMyMember();
    } catch (e: any) {
      console.error('Error in [UserController.setMyMember]: ', e);
      this.flowAPI.errorMonitor.captureException(e);
    }
  }

  private async requestPublicProfile(group: Group) {
    await this.setMyMember();
    const myMember = await this.getMyMember();
    return new Promise<void>((resolve, reject) => {
      if (myMember && isProfilePublic(myMember as any)) {
        resolve();
        return;
      }
      this.setProps({
        userRequestResponse: {
          group,
          exception: UserException.PRIVATE_PROFILE_RESTRICTION,
        },
        userStatus: UserStatus.PRIVATE_PROFILE,
      });
      this.pendingAction = { resolve, reject };
    });
  }

  private async joinGroup(
    group: Group,
    membershipQuestionAnswers?: MembershipQuestionAnswer[],
  ) {
    try {
      const joinGroupResponse = await this.api
        .getGroupMembersApi()
        .joinGroup(group, membershipQuestionAnswers);

      this.flowAPI.fedops.interactionEnded(FEDOPS_GROUP_PAGE__JOIN_TO_GROUP);

      return joinGroupResponse;
    } catch (e: any) {
      return this.handleJoinError(e, group);
    }
  }

  private getUserStatus(): UserStatus {
    if (!this.isUserLoggedIn()) {
      return UserStatus.UNKNOWN;
    }
    if (this.pendingAction) {
      return UserStatus.UPDATING;
    }
    if (this.myMember) {
      return isProfilePublic(this.myMember as any) // TODO
        ? UserStatus.OK
        : UserStatus.PRIVATE_PROFILE;
    }
    return UserStatus.LOGGED_IN;
  }

  private async handleJoinError(e: AmbassadorHTTPError, group: Group) {
    try {
      const errorEvent = errorEventFromAmbassador(e, ErrorOrigin.JoinGroup);
      const { apiError } = errorEvent;
      if (apiError?.includes('Answers to required questions are missing')) {
        return this.handleMembersQuestions(group);
      }
      switch (apiError) {
        case ApiErrorCode.ACCESS_RESTRICTION_NOT_SATISFIED:
          return this.handleAccessRestrictionError(group);
        case ApiErrorCode.ACCESS_RESTRICTION_PAID_PLANS:
          return this.handlePaidPlans(errorEvent, group);
        case ApiErrorCode.ACCESS_RESTRICTION_PAID_PLANS_FUTURE_PLAN_EXISTS:
          return this.handleFuturePlan(errorEvent, group);
      }
    } catch (error) {
      console.log('UserController.handleJoinError FAIL', error);
      this.resetPendingAction();
    }
  }

  private async handleMembersQuestions(group: Group) {
    const userRequestResponse: IUserContext['userRequestResponse'] = {
      group,
      exception: UserException.MEMBERS_QUESTIONS_MISSING,
    };
    this.setProps({
      userRequestResponse,
    });
    const {
      data: { questions },
    } = await this.getHTTPClient().request(
      listMembershipQuestions({ groupId: group.id! }),
    );
    userRequestResponse.questions = questions;
    this.setProps({
      userRequestResponse,
    });
    this.flowAPI.fedops.interactionEnded(FEDOPS_GROUP_PAGE__JOIN_TO_GROUP);
  }

  private async handleAccessRestrictionError(group: Group) {
    const userRequestResponse: IUserContext['userRequestResponse'] = {
      group,
      exception: ApiErrorCode.ACCESS_RESTRICTION_NOT_SATISFIED,
    };
    this.setProps({ userRequestResponse });
    if (restrictedByEvents(group.accessRestriction!)) {
      const eventsIds = getRestrictedEventIds(group);
      try {
        const events = await this.getEvents(eventsIds!);
        userRequestResponse.events = events as any;
        this.setProps({ userRequestResponse });
        this.flowAPI.fedops.interactionEnded(FEDOPS_GROUP_PAGE__JOIN_TO_GROUP);
      } catch (e: any) {
        console.error(
          'Error in [UserController.handleAccessRestrictionError]: ',
          e,
        );
        this.flowAPI.errorMonitor.captureException(e);
      }
    }
  }

  private async getEvents(eventsIds: string[]) {
    const { events } = await this.api.getEventsApi().queryEvents({
      query: {
        filter: {
          eventId: {
            $hasSome: eventsIds,
          },
        },
      },
    });
    return events;
  }

  private shouldTriggerJoin() {
    const { query } = this.getLocation();
    try {
      return query.invite === 'true' || this.inviteFromPlans(query);
    } catch (e) {
      return false;
    }
  }

  private inviteFromPlans(query: { [p: string]: string }) {
    const appSectionParams = query.appSectionParams;
    if (!appSectionParams) {
      return false;
    }
    const params = JSON.parse(appSectionParams) || {};
    return params.invite === INVITE_PAID_PLANS;
  }

  private async getUserPermissions(): Promise<UserPermissions> {
    try {
      const settings = await this.getGroupsSettings();
      const { authorizedGroupCreators } = settings!;
      return {
        canCreateGroup: this.canCreateGroup(authorizedGroupCreators!),
        canResolvePendingGroups: this.isUserLoggedIn() && this.isUserAdmin(),
      };
    } catch (e) {
      console.log('UserController.getUserPermissions Error', e);
      return {};
    }
  }

  private async getGroupsSettings() {
    if (this.settings) {
      return this.settings;
    }
    this.settings = (await this.api.getGroupsAppSettings().getSettings())!;
    return this.settings;
  }
  private canCreateGroup(authorizedGroupCreators: AuthorizedGroupCreators) {
    return (
      this.isUserLoggedIn() &&
      (this.isUserAdmin() ||
        authorizedGroupCreators !== AuthorizedGroupCreators.OWNER)
    );
  }

  private handlePaidPlans(e: IErrorEvent, group: Group) {
    const { requiredPlans } =
      e.applicationError?.getErrorData<IPaidPlans>() || {};
    const userRequestResponse: IUserContext['userRequestResponse'] = {
      group,
      exception: ApiErrorCode.ACCESS_RESTRICTION_PAID_PLANS,
      requiredPlans,
    };
    this.setProps({ userRequestResponse });

    this.flowAPI.fedops.interactionEnded(FEDOPS_GROUP_PAGE__JOIN_TO_GROUP);

    return Promise.resolve(undefined);
  }

  private async pickPricingPlan(userRequestResponse: UserRequestResponse) {
    const plansInstalled = await this.getSiteApis().isAppSectionInstalled(
      PAID_PLANS,
    );

    if (!plansInstalled) {
      userRequestResponse.exception =
        ApiErrorCode.ACCESS_RESTRICTION_PAID_PLANS_NOT_INSTALLED;
      this.setProps({ userRequestResponse });
      return;
    }

    const sectionId = COMPONENT_ID.GROUP;
    const {
      group: { slug },
      requiredPlans,
      futurePlans,
      exception,
    } = userRequestResponse;

    let planIds: string | undefined;
    if (
      exception ===
        ApiErrorCode.ACCESS_RESTRICTION_PAID_PLANS_FUTURE_PLAN_EXISTS &&
      futurePlans
    ) {
      planIds = futurePlans.map((rp) => rp.planId).join(',');
    } else {
      planIds = requiredPlans?.map((rp) => rp.planId).join(',');
    }
    const navigateToSectionProps = {
      appDefinitionId: this.controllerConfig.appParams.appDefinitionId,
      sectionId,
      queryParams: {
        invite: INVITE_PAID_PLANS,
      },
      state: slug!,
      shouldRefreshIframe: true,
    };

    const { t } = this.getTranslations();
    let verticalStatusContent: string | undefined;
    try {
      verticalStatusContent = btoa(
        JSON.stringify({
          buttonText: t('groups-web.restriction.plans.status.cta'),
          contentText: t('groups-web.restriction.plans.status.content'),
        }),
      );
    } catch (e) {}
    const queryParams = {
      planIds,
      navigateToSectionProps: btoa(JSON.stringify(navigateToSectionProps)),
      verticalStatusContent,
      biOptions: btoa(
        JSON.stringify({
          referralInfo: 'pp restricted group',
          referralId: slug,
        }),
      ),
    };
    const qs = `?appSectionParams=${encodeURIComponent(
      JSON.stringify(queryParams),
    )}`;
    const [page] = await this.getAppPages(PAID_PLANS.appDefinitionId);
    this.getLocation().to?.(`${page.url}${qs}`);
    return;
  }

  private handleFuturePlan(e: IErrorEvent, group: Group) {
    const { futurePlans } =
      e.applicationError?.getErrorData<IPaidPlans>() || {};
    const userRequestResponse: IUserContext['userRequestResponse'] = {
      group,
      exception: ApiErrorCode.ACCESS_RESTRICTION_PAID_PLANS_FUTURE_PLAN_EXISTS,
      futurePlans,
    };
    this.setProps({ userRequestResponse });

    this.flowAPI.fedops.interactionEnded(FEDOPS_GROUP_PAGE__JOIN_TO_GROUP);

    return Promise.resolve(undefined);
  }

  private async openUserProfile(siteMemberId: string) {
    try {
      const membersApi =
        await this.controllerConfig.wixCodeApi.site.getPublicAPI(
          MEMBERS_APP_DEF_ID,
        );
      membersApi.navigateToMember({ memberId: siteMemberId });
    } catch (e: any) {
      console.error('Error in [UserController.openUserProfile]: ', e);
      this.flowAPI.errorMonitor.captureException(e);
      this.getLocation().to?.(`/profile/${siteMemberId}`);
    }
  }
}
