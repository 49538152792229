import { Controller } from '../Controller';
import { IJoinGroupRequestApi, RequestState } from '@wix/social-groups-api';

import {
  IJoinGroupRequests,
  JoinGroupRequestType,
  PendingMembers,
} from './IJoinGroupRequests';
import { Member } from '@wix/ambassador-members-v1-member/types';

import {
  ApproveJoinGroupRequestsRequest,
  ApproveJoinGroupRequestsResponse,
  ItemsToUpdate,
  JoinGroupRequest,
  OwnershipFilter,
  QueryJoinGroupRequestsRequest,
  QueryJoinGroupRequestsResponse,
  RejectJoinGroupRequestsRequest,
  RejectJoinGroupRequestsResponse,
} from '@wix/ambassador-social-groups-v2-join-group-request/types';
import { UserRequestResponse } from '../../../../common/context/user/IUserContext';
import { PubSubEventTypes } from '../pubSub/PubSubEventTypes';

export class JoinGroupRequests
  extends Controller<IJoinGroupRequests>
  implements IJoinGroupRequestApi
{
  private pendingRequests: Set<string> = new Set<string>();
  pageReady(): Promise<any> {
    this.setProps({
      joinGroupRequestsActions: {
        queryPendingJoinRequests: this.queryPendingJoinRequests,
        approveJoinGroupRequests: this.approveJoinGroupRequests,
        rejectJoinGroupRequests: this.rejectJoinGroupRequests,
      },
    });
    return Promise.resolve(undefined);
  }

  queryPendingJoinRequests = async (
    payload: QueryJoinGroupRequestsRequest = {},
  ): Promise<void> => {
    const groupId = await this.getGroupId();

    await this.setPendingMembers(
      this._api.getGroupMembersApi().queryPendingJoinRequests({
        groupId,
        ownershipFilter: OwnershipFilter.ALL,
        ...payload,
      }),
    );
    return Promise.resolve(undefined);
  };

  private async setPendingMembers(
    requests: Promise<void | QueryJoinGroupRequestsResponse>,
    joinGroupRequests: IJoinGroupRequests['joinGroupRequestRequests'] = {
      type: JoinGroupRequestType.queryPendingJoinRequests,
    },
  ) {
    this.setProps({
      joinGroupRequestRequests: {
        status: RequestState.PENDING,
        type: JoinGroupRequestType.queryPendingJoinRequests,
        ...joinGroupRequests,
      },
    });
    let props: Partial<IJoinGroupRequests>;
    try {
      const response = await requests;
      if (!response) {
        return;
      }

      const pendingMembers = await this.getPendingMembers(
        response.joinGroupRequests,
      );

      joinGroupRequests!.status = RequestState.SUCCESS;
      props = {
        joinGroupRequestsResponse: Object.assign(response, {
          pendingMembers,
        }),
        joinGroupRequestRequests: joinGroupRequests,
      };
    } catch (e) {
      console.log('[JoinGroupRequests.queryPendingJoinRequests] Error');
      joinGroupRequests!.status = RequestState.ERROR;
      props = {
        joinGroupRequestRequests: joinGroupRequests,
      };
    }
    this.setProps(props);
  }

  private async getPendingMembers(joinGroupRequests: JoinGroupRequest[] = []) {
    if (!joinGroupRequests.length) {
      return;
    }
    const tuples = joinGroupRequests.map((req) => [req.siteMemberId!, req]);
    const _joinGroupRequests = Object.fromEntries(tuples);
    const _members = await this._api
      .getMembersApi()
      .queryMembersByIds(Object.keys(_joinGroupRequests));
    return _members.reduce((acc: PendingMembers, member: Member) => {
      const id = member.id!;
      acc[id] = {
        member,
        joinGroupRequest: _joinGroupRequests[id],
      };
      return acc;
    }, {} as PendingMembers);
  }

  approveJoinGroupRequests = async (
    payload?: ApproveJoinGroupRequestsRequest,
  ): Promise<void | ApproveJoinGroupRequestsResponse> => {
    if (payload?.siteMemberIds) {
      this.pendingRequests.add(payload.siteMemberIds[0]);
    }

    const exec = async (
      resolve: (arg0: void | Promise<QueryJoinGroupRequestsResponse>) => void,
    ) => {
      const groupId = await this.getGroupId();
      await this._api.getGroupMembersApi().approveJoinGroupRequests({
        groupId,
        itemsToApprove: ItemsToUpdate.BY_ID,
        ...payload,
        siteMemberIds: payload?.siteMemberIds ?? [],
      });
      if (payload?.siteMemberIds) {
        this.pendingRequests.delete(payload.siteMemberIds[0]);
      }
      if (this.pendingRequests.size) {
        resolve(undefined);
      } else {
        this.publish<UserRequestResponse>(PubSubEventTypes.JOIN_GROUP, {
          group: { id: payload!.groupId },
        });
        resolve(
          this._api.getGroupMembersApi().queryPendingJoinRequests({
            groupId,
            ownershipFilter: OwnershipFilter.ALL,
            ...payload,
          }),
        );
      }
    };
    await this.setPendingMembers(
      new Promise<void | QueryJoinGroupRequestsResponse>(exec),
      {
        type: JoinGroupRequestType.approveJoinGroupRequests,
        siteMemberIds: Array.from(this.pendingRequests),
      },
    );
    // TODO: update members list
    return Promise.resolve(undefined);
  };

  rejectJoinGroupRequests = async (
    payload?: RejectJoinGroupRequestsRequest,
  ): Promise<void | RejectJoinGroupRequestsResponse> => {
    if (payload?.rejections && payload?.rejections.length) {
      this.pendingRequests.add(payload.rejections[0].siteMemberId!);
    }
    const exec = async (
      resolve: (arg0: void | Promise<QueryJoinGroupRequestsResponse>) => void,
    ) => {
      const groupId = await this.getGroupId();
      await this._api.getGroupMembersApi().rejectJoinGroupRequests({
        groupId,
        itemsToReject: ItemsToUpdate.BY_ID,
        ...payload,
      });
      if (payload?.rejections && payload?.rejections.length) {
        this.pendingRequests.delete(payload.rejections[0].siteMemberId!);
      }
      if (this.pendingRequests.size) {
        resolve(undefined);
      } else {
        resolve(
          this._api.getGroupMembersApi().queryPendingJoinRequests({
            groupId,
            ownershipFilter: OwnershipFilter.ALL,
            ...payload,
          }),
        );
      }
    };
    await this.setPendingMembers(
      new Promise<void | QueryJoinGroupRequestsResponse>(exec),
      {
        type: JoinGroupRequestType.rejectJoinGroupRequests,
        siteMemberIds: Array.from(this.pendingRequests),
      },
    );
    // TODO: update members list
    return Promise.resolve(undefined);
  };
}
